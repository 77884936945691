<template>
  <div id="base-promo">
    <div class="new-section__item__child promo">
      <div class="promo__title">
        Ada Promo Menarik, Lho!
      </div>
      <div class="promo__others" @click="$router.push('/promo')">
        <span style="margin: auto;">Lihat Semua</span>
        <img
          :src="Chevron"
          alt="icon chevron lion parcel"
          width="24"
          height="24"
        />
      </div>
    </div>
    <div
      v-if="!isLoadingToFetch"
      class="new-section__item__child animation-fade-in"
    >
      <Carousel
        :slider-dot-position="setAlignDot"
        :name="carouselName"
        :size="bigScreen ? listChunkPromo.length - 1 : listChunkPromo.length"
        :total-all-data="promos.length"
        color-slider="redWithRound"
        :shimmer="false"
        :auto-play="true"
        :auto-play-interval="5000"
        :scroll-focus="false"
        :is-absolute="true"
        margin-slider="auto -35px"
      >
        <template #item>
          <div
            v-for="(slide, index) in listChunkPromo"
            :id="`${carouselName}-${index + 1}`"
            :key="`${index}-key`"
            class="slide-number"
          >
            <div
              v-for="(promo, j) in slide"
              :id="`id-${carouselName}-${index}-${j}`"
              :key="`key-${index}-${j}`"
              :class="`slide-item class-${carouselName}`"
              style="cursor: pointer;"
              @click="goToLink(promo)"
            >
              <ImageLazy
                :id="`program-promo-${j}`"
                :src="promo.attachment_program_photo_url"
                :alt="promo.alt_program_photo_url"
                class="new-slide-image"
              />
            </div>
          </div>
        </template>
      </Carousel>
    </div>
    <div v-else style="text-align: center" class="new-section__item__child">
      <div v-if="isMobileWidth" class="skeleton-box" />
      <div v-for="sk in defaultSize" v-else :key="sk" class="skeleton-box" />
    </div>
  </div>
</template>

<script>
import Carousel from '../../../components/new-carousel/Carousel';
import { TypePromo } from '../../../app/enum/promo.js';
import ImageLazy from '@/components/ImageLazy/index.vue';
import {
  chunkArray,
  generateChunkCarouselResponsive,
  listenerWindowResize,
  MEDIA,
  removeListenerResize,
} from '../../../components/new-carousel/util.js';
import PromoUseCase from '../../../app/usecase/promo.js';
export default {
  name: 'PromoSection',
  components: { Carousel, ImageLazy },
  data() {
    return {
      Chevron: '/images/chevron_right_red.svg',
      carouselName: 'check-price',
      promos: [],
      isLoadingFetchData: true,
      isMobileWidth: false,
      defaultSize: 1,
      bigScreen: false,
    };
  },
  computed: {
    listChunkPromo: function() {
      if (this.isMobileWidth) {
        return chunkArray(this.promos.slice(0, 4), 1);
      }
      return chunkArray(this.promos, 1);
    },
    setAlignDot() {
      return this.isMobileWidth ? 'start' : 'center';
    },
    isLoadingToFetch: function() {
      return this.isLoadingFetchData;
    },
  },
  async mounted() {
    if (!this.isSsr()) {
      await this.fetchData();
      this.isMobile();
      listenerWindowResize(this.isMobile);
    }
  },
  beforeDestroy() {
    removeListenerResize(this.isMobile);
  },
  methods: {
    isMobile() {
      this.isMobileWidth =
        generateChunkCarouselResponsive(window.innerWidth) === MEDIA().MOBILE;
    },
    goToLink(promo) {
      if (promo.program_type === TypePromo.CONTENT) {
        this.$router.push(`/promo/detail/${promo.program_slug}`);
      } else {
        window.open(promo.program_url, '__blank');
      }
    },
    async fetchData() {
      this.isLoadingFetchData = true;
      const data = await PromoUseCase.getAll(1, 4);
      if (data.err === null) {
        setTimeout(() => {
          this.promos = data.data.slice(0, 4);
          this.isLoadingFetchData = false;
        }, 1000);
      } else {
        this.isLoadingFetchData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
@import '@/assets/css/animation';

.slide-item {
  height: auto;
  overflow: hidden;
  margin: 0 8px;

  .new-slide-image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;

    @include for-size(mobile) {
      width: 75vw;
    }

    @media (max-width: 1024px) {
      min-height: 100px;
    }

    ::v-deep img {
      max-width: 100%;
      position: relative;
      vertical-align: middle;
      left: 50%;
      transform: translate(-50%);
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -ms-transform: translate(-50%);
      -o-transform: translate(-50%);
      width: 964px;
      object-fit: contain;
      -o-object-fit: contain;

      @media (min-width: 600px) and (max-width: 767px) {
        width: 500px;
        height: auto;
      }

      @include for-size(tablet-portrait) {
        width: 675px;
        height: auto;
      }

      @include for-size(mobile) {
        width: auto;
        height: auto;
      }
    }
  }
}

.slide-item:first-of-type {
  margin-left: 0;
}

.slide-item:last-of-type {
  margin-right: 0;
}

.slide-number {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}

.slide-number:first-of-type {
  margin-left: 0;
}

.slide-number:last-of-type {
  margin-right: 0;
}

.new-section__item__child {
  padding: 0;
}

.promo {
  max-width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  color: $color-base;
  font-size: 14px;
  line-height: 18pt;
  font-weight: 600;
  justify-content: space-between;

  @include for-size(mobile) {
    font-size: 12px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $color-gray-shades-6;

    @include for-size(mobile) {
      font-size: 12px;
    }
  }

  &__others {
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      @include for-size(mobile) {
        font-size: 12px;
      }
    }
  }
}

#base-promo {
  margin: auto;
  max-width: 1010px;
  padding: 0 padding-x(1);

  @include for-size(mobile) {
    max-width: unset;
  }

  @include for-size(tablet-portrait) {
    max-width: 720px;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    padding: 0 padding-x(2);
    max-width: unset;
  }
}

.skeleton-box {
  height: 482px;
  width: 964px;
  margin: 8px;

  @include for-size(mobile) {
    height: 38vw;
    width: 80vw;
    display: flex;
    justify-content: start;
  }

  @include for-size(tablet-portrait) {
    height: 335px;
    width: 675px;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    height: 38vw;
    width: 80vw;
    display: flex;
    justify-content: start;
  }
}
</style>
