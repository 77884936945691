<template>
  <div>
    <div v-if="isLoading" style="margin: 100px auto; height: 400px;">
      <Loader
        color-class="l-grey"
        :position-left="isMobileSize ? '40%' : '50%'"
      />
    </div>
    <div
      v-else-if="EVENT && EVENT.status === 'ACTIVE'"
      :key="$route.path"
      class="event-and-tips-article"
    >
      <div class="event-and-tips-article__breadcrumb">
        <Breadcrumb />
      </div>
      <div class="event-and-tips-article__headlines">
        <h1>{{ EVENT.corporate_action_title }}</h1>
        <span>{{ EVENT.corporate_action_author }} ∙ {{ date }}</span>
      </div>

      <ImageLazy
        class="event-and-tips-article__img"
        :src="EVENT.attachment_corporate_action_photo_url"
        :alt="EVENT.alt_photo"
      />

      <div v-if="!isMobileSize" class="event-and-tips-article__social-media">
        <SocialMediaShare
          :data="SocialMediaShareContentData"
          :is-column="true"
        />
      </div>

      <div class="event-and-tips-article__content">
        <div v-if="isLoading" class="event-and-tips-article__content__skeleton">
          <div
            v-for="index of 15"
            :key="index"
            :style="
              [1, 2, 3, 4].includes(index)
                ? `width: ${index + 5}0%`
                : 'width: 100%;'
            "
            class="skeleton-box"
          />
        </div>
        <article v-else v-html="EVENT.corporate_action_content" />
      </div>
      <div class="event-and-tips-article__hashtags">
        <span
          v-for="item of formatedHashTag(EVENT.corporate_action_hashtag_ids)"
          :key="item"
          @click="navigateToProgramList(item)"
        >
          {{ item }}
        </span>
      </div>

      <div
        v-if="isMobileSize"
        class="event-and-tips-article__social-media-mobile"
      >
        <SocialMediaShare
          :data="SocialMediaShareContentData"
          :is-column="false"
        />
      </div>

      <div class="event-and-tips-article__other-list">
        <div class="event-and-tips-article__other-list__title">
          <h5>Event & Tips Lainnya</h5>
          <div @click="navigateToProgramList()">
            <span>Lihat semua</span>
            <img :src="`/images/chevron_right_red.svg`" alt="icon chevron lion parcel" />
          </div>
        </div>
        <div
          :class="[
            'event-and-tips-article__other-list__card',
            { loading: isLoading },
          ]"
        >
          <div
            v-if="isLoading"
            class="event-and-tips-article__other-list__card__skeleton"
          >
            <div v-for="index of 4" :key="index" class="skeleton-box" />
          </div>
          <div
            v-for="(item, index) of otherList.data"
            v-else
            :key="index"
            class="other-card-event-and-tips"
          >
            <router-link :to="`${$route.matched[0].path}/${item.corporate_action_slug}`">
              <ImageLazy
                :src="item.attachment_corporate_action_sub_photo_url"
                :alt="item.alt_sub_photo"
                width="100%"
                height="100%"
                class="other-card-event-and-tips__image"
              />
              <div class="other-card-event-and-tips__title">
                {{ item.corporate_action_title }}
              </div>
              <div class="other-card-event-and-tips__date">
                {{ setTime(item.active_date) }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <NotFound v-else-if="EVENT.status === 'INACTIVE'" />
    <div
      v-if="EVENT && EVENT.status === 'ACTIVE'"
      class="promo-wrapper"
    >
      <PromoSection />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import ImageLazy from '@/components/ImageLazy/index.vue';
import SocialMediaShare from '@/components/social-media-share/SocialMediaShare.vue';
import EventAndTipsUseCase from '../app/usecase';
import { TimeMapper } from '@/misc/timeMapper';
import MixinMobile from '@/misc/mixinMobile';
import MixinMetaInfo from '@/misc/mixinMetaInfo';
import NotFound from '@/pages/not-found/NotFound.vue';
import Loader from '@/components/new-loader/Loader.vue';
import defaultSso from '@/helpers/SEODefault'
import { mapGetters } from 'vuex';
import PromoSection from '../components/PromoSection.vue';

export default {
  components: {
    Breadcrumb,
    ImageLazy,
    SocialMediaShare,
    NotFound,
    Loader,
    PromoSection
  },
  mixins: [MixinMobile, MixinMetaInfo],
  metaInfo() {
    if (this.EVENT?.corporate_action_title) {
      const keyword = this.EVENT.corporate_action_title
        .toLowerCase()
        .split(' & ')
        .join(' ')
        .split(', ')
        .join(' ')
        .split(' ')
        .join(', ');
      return this.SEO({
        channel: 'article',
        title: this.EVENT.corporate_action_title,
        description:
          this.EVENT.meta_info_description ||
          `${this.EVENT.corporate_action_type} - ${this.EVENT.corporate_action_title}`,
        keywords:
          this.EVENT.meta_info_keyword ||
          `${this.EVENT.corporate_action_type.toLowerCase()}, ${keyword}, ${
            this.metaInfo.meta_info_keyword
          }`,
        publishedTime: `${new Date(this.EVENT.active_date).toISOString()}T+07:00`,
        image: this.EVENT.attachment_corporate_action_photo_url,
      });
    } else {
      return this.SEO({
        channel: 'article',
        ...defaultSso
      })
    }
  },
  data() {
    return {
      searchMetaInfo: '_event-and-tips-detail',
      data: {},
      otherList: {},
      isLoading: false,
      date: '',
      SocialMediaShareContentData: {
        url: new URL(
          !this.isSsr()
            ? location.href
            : `http://${process.env.DEV_HOST}:${process.env.APP_PORT}`,
        ).href,
        title: '',
        description: '',
        quote: '',
        hashtags: 'lionparcel',
      },
    };
  },
  async asyncData({ route, store }) {
    const data = await EventAndTipsUseCase.getProgramDetail(
      route.params.corporate_slug,
    );
    if (data && data.data && !data.err && data.data.status !== 'INACTIVE') {
      store.dispatch('setEvent', data.data);
    } else {
      store.dispatch('setEvent', { status: 'INACTIVE' });
    }
  },
  computed: {
    ...mapGetters(['EVENT']),
  },
  watch: {
    '$route.path': {
      handler() {
        this.getProgramDetail();
        this.getAllProgram();
        if (!this.isSsr()) {
          window.scrollTo(0, 0);
        }
        this.getMetaInfo();
      },
      deep: true,
    },
  },
  mounted() {
    this.getProgramDetail();
    this.getAllProgram();
    window.scrollTo(0, 0);
  },
  methods: {
    async getProgramDetail() {
      this.isLoading = true;
      const data = await EventAndTipsUseCase.getProgramDetail(
        this.$route.params.corporate_slug,
      );
      if (data && data.data && !data.err && data.data.status !== 'INACTIVE') {
        this.$store.dispatch('setEvent', data.data)
        this.date = this.setTime(data.data.active_date);
        if (data.data.corporate_action_content.match('instagram-media')) {
          const instagramScript = document.createElement('script');
          instagramScript.setAttribute('async', '');
          instagramScript.setAttribute('src', '//www.instagram.com/embed.js');
          document.head.appendChild(instagramScript);
        }

        this.SocialMediaShareContentData.url = new URL(location.href).href;
        const copyUrl = this.SocialMediaShareContentData.url;
        this.SocialMediaShareContentData = {
          ...this.SocialMediaShareContentData,
          title: data.data.corporate_action_title,
          copyUrl,
        };
      } else {
        this.$store.dispatch('setEvent', { status: 'INACTIVE' })
      }
      this.isLoading = false;
    },
    async getAllProgram() {
      this.isLoading = true;
      this.articleType = this.$route.matched[0].path.replace('/info-', '').toUpperCase()
      const data = await EventAndTipsUseCase.getAllProgram(1, 4, '', this.articleType)
      if (data && !data.err) {
        this.otherList = data.data;
      }
      this.isLoading = false;
    },
    formatedHashTag(string) {
      return string ? string.split(',').filter(val => val) : '';
    },
    setTime(date) {
      return TimeMapper.SetFormatDate(
        date,
        true,
        [' ', ' ', '-', ':'],
        false,
        false,
        false,
      );
    },
    navigateToProgramList(hastag) {
      if (!hastag) {
        this.$router.push(this.$route.matched[0].path);
      } else {
        this.$router.push(`${this.$route.matched[0].path}#${hastag}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

::v-deep iframe {
  @include for-size(mobile) {  
    &[src*='youtu'] {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
    }
  }
}
</style>
