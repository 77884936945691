<template>
  <div class="card-event-and-tips">
    <router-link :to="link">
      <ImageLazy
        :src="src"
        :alt="alt"
        width="300"
        height="200"
        class="card-event-and-tips__image"
      />
      <div class="card-event-and-tips__title">
        {{ title }}
      </div>
      <div class="card-event-and-tips__date">
        {{ date }}
      </div>
    </router-link>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index';

export default {
  components: { ImageLazy },
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      require: true,
      default: '#'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
@import '../style.scss';

.card-event-and-tips {
  width: 100%;
  max-width: 300px;
  padding-top: 30px;

  &__image {
    > ::v-deep img {
      border-radius: 8px;
      min-width: 300px;
      min-height: 200px;
    }
  }

  &__title {
    border-top: 1px solid $color-white-dark;
    padding-top: 10px;

    @include typography(16px, $color-gray-shades-6, 24px, 600);
  }

  &__date {
    @include typography(12px, $color-gray-shade, 18px, 500);
  }
}
</style>
