<template>
  <div class="event-and-tips">
    <Breadcrumb
      class="breadcrumb"
    />
    <ImageLazy
      :src="getBanner"
      alt="event and tips lion parcel"
      class="event-and-tips__banner"
    />
    <div class="event-and-tips__headlines">
      <h1>Selalu Ada Yang Baru</h1>
      <h4>Dari Beragam Artikel Seru</h4>
    </div>
    <div class="event-and-tips__content">
      <div class="event-and-tips__content__form">
        <Input
          v-model="search"
          name="search"
          placeholder="Cari Event & Tips"
          custom-class="event-and-tips-input"
          custom-placeholder="placeholder-lg"
          @input="(event) => onSearch(event)"
        >
          <template #icon>
            <img
              id="search-icon"
              src="/images/eventandtips/search-icon.svg"
              alt="icon search"
              width="20"
              height="20"
            >
          </template>
        </Input>
        <div class="event-and-tips__content__form__dropdown">
          <Dropdown
            :options="filters"
            :is-remove-shadow="true"
            :value="filterSelect"
            @input="onChangeFilter"
          />
        </div>
      </div>

      <Loader
        v-show="isLoading"
        class="l-grey"
      />

      <!-- suggestion article -->
      <div
        v-if="Boolean(suggestedArticle.corporate_action_id)"
        class="event-and-tips__content__suggestion"
        @click="navigateToDetail"
      >
        <ImageLazy
          :src="suggestedArticle.attachment_corporate_action_photo_url"
          :alt="suggestedArticle.alt_photo"
          class="event-and-tips__content__suggestion__img"
        />
        <div class="event-and-tips__content__suggestion__tag">
          <span>Artikel Untukmu</span>
        </div>
        <div class="event-and-tips__content__suggestion__headlines">
          <h1>{{ suggestedArticle.corporate_action_title }}</h1>
          <span>{{ setTime(suggestedArticle.active_date) }}</span>
        </div>
      </div>

      <div
        v-if="isLoading"
        class="event-and-tips_skeleton"
      >
        <div
          v-for="item of 3"
          :key="item"
        >
          <div
            v-for="index of 3"
            :key="index"
            class="event-and-tips-list"
          >
            <div
              v-for="skeleton of 5"
              :key="skeleton"
              class="skeleton-box"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="event-and-tips__content__list"
      >
        <Card
          v-for="(item, index) of EVENTS"
          :key="`card-${item.corporate_action_id}-${index}`"
          :src="item.attachment_corporate_action_sub_photo_url"
          :alt="item.alt_sub_photo"
          :title="item.corporate_action_title"
          :date="setTime(item.active_date)"
          :link="`${$route.path}/${item.corporate_action_slug}`"
        />
      </div>
      <div class="event-and-tips__content__pagination">
        <Pagination
          v-model="pagination.page"
          :per-page="pagination.perPage"
          :total-all-item="pagination.totalAllItem"
          @next="mainPage('next')"
          @prev="mainPage('prev')"
        />
      </div>
    </div>
    <div class="mt-5" />
    <PromoSection />
  </div>
</template>

<script>
import Breadcrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import ImageLazy from '@/components/ImageLazy/index';
import MixinMobile from '@/misc/mixinMobile';
import Input from '@/components/new-input/Input.vue';
import Dropdown from '@/components/new-dropdown/Dropdown.vue';
import Card from './components/Card.vue';
import Pagination from '@/components/new-pagination/Pagination.vue';
import EventAndTipsUseCase from './app/usecase';
import { TimeMapper } from '@/misc/timeMapper';
import MixinMetaInfo from '@/misc/mixinMetaInfo';
import Loader from '@/components/new-loader/Loader.vue';
import { mapGetters } from 'vuex';
import PromoSection from './components/PromoSection.vue';

export default {
  name: 'EventAndTips',
  components: {
    Breadcrumb,
    ImageLazy,
    Input,
    Dropdown,
    Card,
    Pagination,
    Loader,
    PromoSection
  },
  mixins: [MixinMobile, MixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: `_${this.$route.path.split('/')[1]}`,
      isLoading: false,
      titleBreadcrumb: '',
      selected: [],
      suggestedArticle: {},
      search: '',
      articleType: null,
      hashtag: '',
      filters: [
        {
          value: '',
          label: 'Semua',
        },
        {
          value: 'Event',
          label: 'Event',
        },
        {
          value: 'Tips',
          label: 'Tips',
        },
      ],
      banner: {
        mitra: {
          hero: '/images/eventandtips/banner_desktop_mitra.webp',
          heroMobile: '/images/eventandtips/banner_mobile_mitra.webp',
        },
        seller: {
          hero: '/images/eventandtips/banner_desktop_seller.webp',
          heroMobile: '/images/eventandtips/banner_mobile_seller.webp',
        },
      },
      pagination: {
        page: 1,
        perPage: 7,
        totalAllItem: 0,
      },
      debounce: {
        timeout: 0,
        duration: 1000,
      },
    };
  },
  async asyncData({ route, store }) {
    const data = await EventAndTipsUseCase
      .getAllProgram(
        1,
        7,
        '',
        route.path.replace('/info-', '').toUpperCase()
      );
    if (data && !data.err) {
      if (!data.data.data.length) {
        store.dispatch('setEvents', []);
      }
      store.dispatch('setEvents', data.data.data);
    }
  },
  computed: {
    ...mapGetters(['EVENTS']),
    getBanner() {
      const router = this.$route.path;
      if (router.includes('info-seller')) {
        return this.isMobileSize
          ? this.banner.seller.heroMobile
          : this.banner.seller.hero;
      }
      return this.isMobileSize
        ? this.banner.mitra.heroMobile
        : this.banner.mitra.hero;
    },
    filterSelect() {
      return this.selected;
    },
  },
  watch: {
    $route: {
      handler() {
        this.articleType = this.$route.path.replace('/info-', '').toUpperCase();
        this.pagination.page = 1;
        this.hashtag = '';
        this.search = '';
        this.selected = [this.filters[0]];
        this.searchMetaInfo = `_${this.$route.path.split('/')[1]}`;
        this.getMetaInfo();
        this.getAllProgram();
        if (!this.isSsr()) {
          window.scrollTo(0, 0)
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
    this.articleType = this.$route.path.replace('/info-', '').toUpperCase();
    this.selected = [this.filters[0]];
    this.hashtag = this.$route?.hash.replace('#', '');
    this.getAllProgram();
  },
  methods: {
    async getAllProgram() {
      this.isLoading = true;
      const data = await EventAndTipsUseCase
        .getAllProgram(
          this.pagination.page,
          this.pagination.perPage,
          this.filterSelect[0]?.value,
          this.articleType,
          this.hashtag,
          this.search,
        );
      if (data && !data.err) {
        this.isLoading = false;
        if (!data.data.data.length) {
          this.$store.dispatch('setEvents', []);
          this.suggestedArticle = {};
          this.pagination = {
            page: 1,
            perPage: 7,
            totalAllItem: 0,
          };
          return;
        }
        this.suggestedArticle = data.data.data.shift();
        this.$store.dispatch('setEvents', data.data.data);
        this.pagination = {
          ...this.pagination,
          page: Number(data.data.pagination.page),
          totalAllItem: data.data.pagination.total_all_item,
        };
      }
    },
    mainPage(type) {
      if (type === 'next') {
        this.pagination.page++;
        this.getAllProgram(this.pagination.page, this.pagination.perPage);
      }

      if (type === 'prev' && this.pagination.page > 1) {
        this.pagination.page--;
        this.getAllProgram(this.pagination.page, this.pagination.perPage);
      }
      if (!this.isSsr()) {
        window.scrollTo(0, 800);
      }
    },
    setTime(date) {
      return TimeMapper.SetFormatDate(date, true, [' ', ' ', '-', ':'], false, false, false);
    },
    onSearch(value) {
      if (this.debounce.timeout) {
        clearTimeout(this.debounce.timeout);
      }
      this.debounce.timeout = setTimeout(() => {
        this.pagination.page = 1;
        this.getAllProgram(value);
      }, this.debounce.duration);
    },
    navigateToDetail() {
      this.$router.push(`/info-${this.suggestedArticle.article_info.toLowerCase()}/${this.suggestedArticle.corporate_action_slug}`);
    },
    onChangeFilter() {
      this.pagination.page = 1;
      this.getAllProgram();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
